.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    float: right
}
.pagination > .active > a{
    background-color: #6CBC83 ;
    border-color: #6CBC83 ;
    color: white!important;
}
.pagination > li > a{
    border: 1px solid #6CBC83 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    margin-right: 0.5rem;
    border-radius: 5px;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #6CBC83 ;
    border-color: #6CBC83;
    outline: none ;
}
.pagination > li > a, .pagination > li > span{
    color: #6CBC83;
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: 5px;
}