.dataTables_wrapper .dataTables_paginate {
    padding-bottom: 0.5rem;
    padding-left: 5rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: white!important;
    border: 1px solid hsl(137, 37%, 58%);
    background: #6CBC83;
    font-size: 11px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    border: 1px solid #6CBC83;
    font-size: 11px;
    border-radius: 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: #6CBC83;
    border: 1px solid #6CBC83;
}

table.dataTable thead th, table.dataTable thead td {
    border-bottom: 2px solid #eff2f7!important
}

table.dataTable.no-footer {
    border-bottom: 2px solid #eff2f7!important
}

table.dataTable tfoot th, table.dataTable tfoot td {
    border-top: 2px solid #eff2f7!important;
    border-bottom: 2px solid #eff2f7!important;
}

.dataTables_wrapper .dataTables_paginate {
    padding-top: 0.5rem;
}

table.dataTable thead .sorting_desc {
    background-image: none!important;
}

table.dataTable thead .sorting_asc {
    background-image: none!important;
}

table.dataTable thead .sorting {
    background-image: none;
}